import { ReactElement } from 'react';
import { ProfileNote } from '../../../store';
import { NoteCustomFields, AddFieldButton, FixedNoteField } from '../EditNote/EditNote';
import { MutableCustom } from '../../../utils/inputUtils';
import { Box } from '@mui/material';

interface Props {
  profileNote: ProfileNote;
  changeFunc: (field: string, custom?: boolean, label?: boolean) => ((newValue: string) => void);
  handleCustomChange: (index: number, label?: boolean) => ((newValue: string) => void);
  mutableCustom: MutableCustom;
  addCustomField: () => void;
  onDelete: (index: number) => (() => void);
}

export const EditProfileNote = 
({ profileNote, changeFunc, mutableCustom, addCustomField, onDelete, handleCustomChange}: Props): ReactElement => {

  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <FixedNoteField fieldLabelKey="title" placeholder="Enter a title" 
        value={profileNote.title || ''} changeFunc={changeFunc('title')}/>

      <FixedNoteField fieldLabelKey="notes" placeholder="Enter notes" 
        value={profileNote.notes} changeFunc={changeFunc('notes')} multiline minRows={2}/>
        
      <NoteCustomFields mutableCustom={mutableCustom} changeFunc={handleCustomChange} onDelete={onDelete}/>

      <AddFieldButton onClick={addCustomField}/> 
    </Box>
  );
};

export default EditProfileNote;