import { ReactElement } from 'react';
import { AirlineNote } from '../../../store';
import { MutableCustom } from '../../../utils/inputUtils';
import { NoteCustomFields, FixedNoteField, AddFieldButton } from '../EditNote/EditNote';
import { NEW_AIRLINE_SRC } from '../../../resourceUrls';
import { Box, Typography } from '@mui/material';


interface Props {
  airlineNote: AirlineNote;
  changeFunc: (field: string, custom?: boolean, label?: boolean) => ((newValue: string) => void);
  handleCustomChange: (index: number, label?: boolean) => ((newValue: string) => void);
  headerChange: (field: string, value: string) => void;
  mutableCustom: MutableCustom;
  addCustomField: () => void;
  onDelete: (index: number) => (() => void);
}

export const EditAirlineNote = 
({ airlineNote, changeFunc, headerChange, mutableCustom, addCustomField, onDelete, 
  handleCustomChange }: Props): ReactElement => {

  const renderNameOrInput = (): ReactElement => {
    if (airlineNote.airline_data.id === -1) {
      return (
        <>
          <Box display='flex' gap={1} marginTop={1} marginBottom={1}>
            <Box component={'img'} src={airlineNote.airline_data.icon_url || NEW_AIRLINE_SRC} width={24} />
          </Box>
          <FixedNoteField fieldLabelKey="airline_name" placeholder="Enter a name" 
            value={airlineNote.airline_data.name} changeFunc={(value): void => headerChange('name', value || '')}/>
        </>
      );
    } else {
      return (
        <Box display='flex' gap={1} marginTop={1} marginBottom={1}>
          <Box component={'img'} src={airlineNote.airline_data.icon_url || NEW_AIRLINE_SRC} width={24} />
          <Typography fontWeight={700}>{airlineNote.airline_data.name}</Typography>
        </Box>
      );
    }
  };
  
  return (
    <Box display='flex' flexDirection='column' gap={1}>
      {renderNameOrInput()}
      {!airlineNote.airline_data.is_default &&  
      <>
        <FixedNoteField 
          fieldLabelKey="airline_phone_number" placeholder="Enter a number"
          value={airlineNote.airline_data.phone_number} 
          changeFunc={(value): void => headerChange('phone_number', value || '')}/>
        <FixedNoteField fieldLabelKey="airline_website_url" placeholder="Enter website"
          value={airlineNote.airline_data.website_url}
          changeFunc={(value): void => headerChange('website_url', value || '')}/>
      </>}

      <FixedNoteField fieldLabelKey="loyalty_number" placeholder="Enter Number" 
        value={airlineNote.loyalty_number} changeFunc={changeFunc('loyalty_number')}/>

      <FixedNoteField fieldLabelKey="username" placeholder="Enter username" 
        value={airlineNote.username} changeFunc={changeFunc('username')}/>

      <FixedNoteField fieldLabelKey="notes" placeholder="Enter notes" 
        value={airlineNote.notes} changeFunc={changeFunc('notes')} multiline minRows={2}/>

      <NoteCustomFields mutableCustom={mutableCustom} changeFunc={handleCustomChange} onDelete={onDelete} />

      <AddFieldButton onClick={addCustomField}/>
    </Box>
  );
};

export default EditAirlineNote; 