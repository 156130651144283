import { ReactElement } from 'react';
import { Hotel } from '../../../store/hotels/types';
import { formatPhoneNumber } from '../../../utils/renderUtils';
import { NEW_HOTEL_SRC } from '../../../resourceUrls';
import { Box, Typography } from '@mui/material';

interface Props {
  hotel_data: Hotel;
}

export const HotelCardHeader = ({ hotel_data }: Props): ReactElement => {
  const formattedPhoneNumber = formatPhoneNumber(hotel_data.phone_number);

  return (
    <Box display='flex' flexDirection='column' gap={1} overflow='hidden'>
      <Box marginTop={1} display='flex' alignItems='center'>
        <Box component='img' src={hotel_data.icon_url || NEW_HOTEL_SRC} width={40} />
        <Typography marginLeft={1} fontSize={22} fontWeight='bold'>{hotel_data.name}</Typography>
      </Box>
      <Box display='flex' flexDirection='column' gap={.5}>
        <Box display='flex' alignItems='center' gap={1}>
          <Typography minWidth={75} fontWeight='bold'>Phone</Typography>
          <Typography onClick={(event) => event.stopPropagation()} noWrap textOverflow='ellipsis' 
            sx={{userSelect: 'text'}}>
            <a href={`tel:${formattedPhoneNumber}`}>
              {formattedPhoneNumber}
            </a>
          </Typography>
        </Box>
      
        <Box display='flex' alignItems='center' gap={1} width='100%'>
          <Typography minWidth={75} fontWeight='bold'>Website</Typography>
          <Typography onClick={(event) => event.stopPropagation()} noWrap textOverflow='ellipsis' 
            sx={{userSelect: 'text'}}>
            <a href={hotel_data.website_url} target="_blank" rel="noopener noreferrer">
              {hotel_data.website_url}
            </a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HotelCardHeader;