import React, { ReactElement } from 'react';
import { CabButton } from '@CabComponents';
import { Box, Typography, styled } from '@mui/material';

interface Props {
  openEdit?: () => void;
}

const HeaderBox = styled(Box, {label: "HeaderBox"})({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center'
});

export const LeaderCardHeader = ({ openEdit }: Props): ReactElement => {

  const handleEditPressed = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation();
    if (openEdit) openEdit();
  };

  return (
    <HeaderBox>
      <Typography variant='h4'>Additional Details</Typography>
      {openEdit &&
        <CabButton buttonType='text' color='primary' sx={{fontSize: '14px'}} onClick={handleEditPressed}>
          Edit
        </CabButton>
      }
    </HeaderBox>
  );
};

export default LeaderCardHeader;