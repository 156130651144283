import { bindActionCreators } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import store, { actions, Airline, Country, Hotel, Industry, StaticData } from '..';

const actionCreators = bindActionCreators({
  fetchAirlines: actions.staticData.fetchAirlines, 
  fetchCountries: actions.staticData.fetchCountries,
  fetchIndustries: actions.staticData.fetchIndustries,
  fetchHotels: actions.staticData.fetchHotels,
}, store.dispatch);

type IFetchStaticData = typeof actionCreators.fetchAirlines | typeof actionCreators.fetchCountries | 
typeof actionCreators.fetchHotels | typeof actionCreators.fetchIndustries;

type IStaticData = StaticData['airlines'] | StaticData['countries'] | 
StaticData['hotels'] | StaticData['industries'];

const selectCachedData = <T>(fetchDataFunc: IFetchStaticData) => (data: IStaticData, fetchingData: boolean) => {
  if (!data.length && !fetchingData) {
    fetchDataFunc();
  }
  return data as T;
};

export const selectAirlines = createSelector([
  (state: StaticData) => state.airlines,
  (state: StaticData) => state.fetchingAirlines,
], selectCachedData<Airline[]>(actionCreators.fetchAirlines));

export const selectCountries = createSelector([
  (state: StaticData) => state.countries,
  (state: StaticData) => state.fetchingCountries,
], selectCachedData<Country[]>(actionCreators.fetchCountries));

export const selectIndustries = createSelector([
  (state: StaticData) => state.industries,
  (state: StaticData) => state.fetchingIndustries,
], selectCachedData<Industry[]>(actionCreators.fetchIndustries)); 

export const selectHotels = createSelector([
  (state: StaticData) => state.hotels,
  (state: StaticData) => state.fetchingHotels,
], selectCachedData<Hotel[]>(actionCreators.fetchHotels));