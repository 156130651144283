import { ReactElement } from 'react';
import { CabButton, CabCollapseMenu, CabIcon } from '@CabComponents';
import { Box, styled } from '@mui/material';
import colors from '../../colors';
import { 
  IoCreateOutline, IoTrashOutline, IoEllipsisHorizontal, IoChevronForward, IoChevronDown 
} from 'react-icons/io5';
 
interface Props {
  collapsed: boolean;
  toggleCollapsed: () => void;
  openDelete?: () => void;
  openEdit?: () => void;
}

export const InfoCardControls = 
({ collapsed, toggleCollapsed, openDelete, openEdit }: Props): ReactElement => {


  const handleClickEdit = (): void => {
    if (openEdit) openEdit();
  };
  
  const handleClickDelete = (): void => {
    if (openDelete) openDelete();
  };


  const renderControls = (): ReactElement => {
    return (
      <Box display='flex' gap={2} alignItems='center'>
        <CabCollapseMenu 
          buttons={<>
            <StyledGroupButton
              buttonType="text"
              onClick={handleClickEdit} 
              icon={<CabIcon Icon={IoCreateOutline} color={colors.black900}/>}
            >
              Edit
            </StyledGroupButton>
            <StyledGroupButton
              buttonType="text"
              onClick={handleClickDelete} 
              icon={<CabIcon Icon={IoTrashOutline} color={colors.black900}/>}
            >
              Delete
            </StyledGroupButton>
          </>}
          popOverTitle=""
          target={<CabIcon
            Icon={IoEllipsisHorizontal}
            sx={{textAlign: "right", fontSize: 20}}
          />}
          buttonGroupColor="inherit"
          buttonGroupVariant="outlined"
          buttonGroupSx={{paddingTop: 1, paddingBottom: 1}}
          popOverSx={{marginTop: -1}}
        />
        <CabIcon Icon={collapsed ? IoChevronForward : IoChevronDown} onClick={toggleCollapsed} 
          sx={{color: colors.black900}} />
      </Box>
    );
  };


  return (
    <Box position={'absolute'} top={11} right={8} onClick={(event) => event.stopPropagation()}>
      {renderControls()}
    </Box>
  );
};

export default InfoCardControls;

const StyledGroupButton = styled(CabButton, {label: "StyledGroupButton"})({
  justifyContent: 'start', 
  color: colors.black900, 
  paddingBottom: 8, 
  paddingTop: 8,
  paddingLeft: 15, 
  paddingRight: 15, width: '100%'
});

