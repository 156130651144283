import {ReactElement } from 'react';
import { Clipboard } from '@capacitor/clipboard';
import { CabIcon } from '@CabComponents';
import { IoCopyOutline } from 'react-icons/io5';
import colors from '../../colors';
import { useDispatch } from 'react-redux';
import { sendMessage } from '../../store/globalMessages/actions';
import { ThunkDispatchType } from '../../store';
import { Box, Typography } from '@mui/material';

interface Props {
  label: string;
  value: string;
  fieldIndex?: number;
}

export const InfoCardField = ({ label, value, fieldIndex }: Props): ReactElement => {

  const dispatch = useDispatch<ThunkDispatchType>();

  const handleCopyToClipboard = async () => {
    await Clipboard.write({ string: value });
    dispatch(sendMessage({
      timeout: 750,
      message: 'Copied',
      header: "",
      position: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      active: true,
      severity: 'success',
      autoDismiss: true
    }));
  };

  //TODO: fix styles for this to make them more responsive
  if (value) {
    return (
      <Box display='flex' justifyContent={'space-between'} 
        borderTop={fieldIndex === 0 ? 0 : `1px solid ${colors.black200}`}
      >
        <Box paddingTop={1} paddingBottom={1}>
          <Typography fontWeight={600}>{label}</Typography>
          <Typography fontSize={14}>{value}</Typography>
        </Box>
        <CabIcon
          alt={`Copy ${label}`}
          Icon={IoCopyOutline}
          sx={{ fontSize: 26, color: colors.greenPrimary }}
          onClick={handleCopyToClipboard}
        />
      </Box>
    );
  } else {
    return <></>;
  }
};

export default InfoCardField;


  
