import React, { ReactElement, useState, useEffect } from 'react';
import { AirlineNote, PersonalNote, Leader, ProfileNote } from '../../store';
import { InfoCardFields } from '../../utils/types';
import InfoCardControls from './InfoCardControls';
import InfoCardField from './InfoCardField';
import { 
  MutableCustom, customToMutableCustom, mutableCustomToCustom, isUniqueCustomLabel, getNewMutableCustomField,
  FieldErrors, addFieldError, removeFieldError
} from '../../utils/inputUtils';
import EditAirlineNote from './AirlineCard/EditAirlineNote';
import AirlineCardHeader from './AirlineCard/AirlineCardHeader';
import PersonalCardHeader from './PersonalCard/PersonalCardHeader';
import EditPersonalNote from './PersonalCard/EditPersonalNote';
import { AllNotes, AnyNote, HotelNote} from '../../store';
import { NoteType, NOTE_FIELD_LABELS } from '../../constants';
import LeaderCardHeader from './LeaderCard/LeaderCardHeader';
import EditLeaderNote from './LeaderCard/EditLeaderNote';
import EditHotelNote from './HotelCard/EditHotelNote';
import HotelCardHeader from './HotelCard/HotelCardHeader';
import { ERROR_TYPE } from '../../constants';
import EditProfileNote from './ProfileCard/EditProfileNote';
import ProfileCardHeader from './ProfileCard/ProfileCardHeader';
import { CabButton, CabModal } from '@CabComponents';
import { Box, styled } from '@mui/material';
import colors from '../../colors';

interface Props<T> {
  note: T;
  openDeleteAlert?: () => void;
  saveNote: (note: T) => Promise<void>;
  cancelAdd?: () => void;
  addMode: boolean;
  infoFields: InfoCardFields<AllNotes>[];
  title: string;
  noteType: NoteType;
  currentLeader: Leader | undefined;
}

export function InfoCardContainer<T extends AnyNote>({note, openDeleteAlert, cancelAdd, addMode, title, infoFields, 
  noteType, saveNote, currentLeader }: Props<T>): ReactElement {

  const [collapsed, setCollapsed] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [noteCopy, setNoteCopy] = useState(note);
  const [mutableCustom, setMutableCustom] = useState<MutableCustom>([]);
  //open is controlling the modal open/closed save is controling save/cancel on close
  //shown is if the modal has been shown
  const [addModal, setAddModal] = useState(addMode);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const editing = editMode || addMode;
  
  useEffect(() => {
    if ((editMode || addMode) && !addModal) {
      setAddModal(true);
    }
  }, [addMode, addModal, editMode]);
  
  // Update mutableCustom when the original custom changes
  useEffect((): void => {
    // We can't actually edit the keys of the object because it will keep changing in the frontend
    // Doing it this way separates the field names from the actual object keys
    setMutableCustom(customToMutableCustom(note.custom));
  }, [note.custom, editing]);

  // Update noteCopy whenever the original personalNote changes
  useEffect((): void => {
    setNoteCopy(note);
  }, [note]);

  const handleToggleCollapsed = (): void => {
    setCollapsed(!collapsed);
  };

  const handleDeleteCustomField = (index: number): (() => void) => (): void => {
    let newCustom = [...mutableCustom];
    newCustom.splice(index, 1);
    // Since we are removing what is potentially a duplicate label, we need to
    // check the whole array of fields to re-check the unique status of them
    newCustom = newCustom.map(field1 => ({
      ...field1, 
      isUnique: newCustom.filter(field2 => (field1.label === field2.label)).length <= 1
    }));
    setMutableCustom(newCustom);
  };

  const getChangeFunction = (field: string): ((newValue: string) => void) => {
    return (newValue: string | Record<string, unknown> | number): void => {
      setNoteCopy({...noteCopy, [field]: newValue});
    };
  };

  const handleSetNewValue = (field: string, newValue: string) => {
    setNoteCopy({...noteCopy, [field]: newValue});
  };

  const getCustomChange = (index: number, label = false): ((newValue: string) => void) => {
    const mutableCustomCopy = [...mutableCustom];
    if (label) {
      return (newValue: string): void => {
        mutableCustomCopy[index] = 
          {...mutableCustom[index], label: newValue, isUnique: isUniqueCustomLabel(mutableCustom, newValue)};
        setMutableCustom(mutableCustomCopy);
      };
    } else {
      return (newValue: string): void => {
        mutableCustomCopy[index].value = newValue;
        setMutableCustom(mutableCustomCopy);
      };
    }
  };

  const changeHeader = (field: string, newValue: string): void => {
    if (noteType === NoteType.AIRLINE) {
      setNoteCopy({...noteCopy, airline_data: {...(noteCopy as AirlineNote).airline_data, [field]: newValue}});
    } else if (noteType === NoteType.HOTEL) {
      setNoteCopy({...noteCopy, hotel_data: {...(noteCopy as HotelNote).hotel_data, [field]: newValue}});
    }
  };

  const handleEdit = (): void => {
    setCollapsed(false);
    setEditMode(true);
  };

  const handleAddCustomField = (): void => {
    setMutableCustom([...mutableCustom, getNewMutableCustomField()]);
  };

  const handleAddError = (fieldKey: keyof typeof NOTE_FIELD_LABELS, errorType: keyof typeof ERROR_TYPE): void => {
    setFieldErrors(addFieldError(fieldErrors, fieldKey, errorType));
  };

  const handleRemoveError = (fieldKey: string, errorType: keyof typeof ERROR_TYPE): void => {
    setFieldErrors(removeFieldError(fieldErrors, fieldKey, errorType));
  };

  const getErrors = (): boolean => {
    // Check if there are duplicate keys in custom fields
    const nonUniqueCustomLabels = 
      Object.values(mutableCustom).filter(field => !field.isUnique).map(field => field.label);
    const customErrorMessage = nonUniqueCustomLabels.length 
      ? `You cant have duplicate fields: ${nonUniqueCustomLabels.join(', ')}` 
      : '';
    // Check if there are any validation errors for default fields
    const fieldMessages: string[] = [];
    Object.values(fieldErrors).forEach((curFieldErrors): void => {
      Object.values(curFieldErrors).forEach((error): void => {
        if (!error.valid) {
          fieldMessages.push(error.message);
        }
      });
    });
    // Combine errors from custom fields and default fields
    const errors = customErrorMessage ? [...fieldMessages, customErrorMessage] : fieldMessages;
    setErrorMessage(errors.join(''));
    //return true there are errors
    return errors.length !== 0;
  };

  const handleSave = (): void => {
    if (!getErrors()) {
      setIsSaving(true);
      const [customToSave] = mutableCustomToCustom(mutableCustom);
      saveNote({...noteCopy, custom: customToSave})
        .then((): void => {
          setEditMode(false);
          setAddModal(false);
          setIsSaving(false);
        })
        .catch((error): void => console.log(error));
    } else {
      setShowErrorAlert(true);
    }
  };

  const handleCancel = (): void => {
    setEditMode(false);
    setAddModal(false);
    setNoteCopy(note);
    if (cancelAdd) {
      cancelAdd();
    }
  };

  const renderCustomFields = (): ReactElement => (
    <>
      {Object.values(mutableCustom).map((field, index): ReactElement | void => 
        <InfoCardField key={field.uniqueKey} label={field.label} value={field.value} />
      )}
    </>
  );

  const renderInfoCardFields = (): ReactElement => {
    return (
      <> 
        {infoFields.map((field, i): ReactElement => {
          // Allow any field from AirlineNote or PersonalNote
          const fieldKey = field.key;
          // The value will only be from a subset of fields which have string value
          // TODO: Typing should be specified here to indicate that the values will
          // only come from note fields with keys in infoFields
          // Fixing it with (note as AllNotes) means we are unprotected against fields
          // that are not present in the note type used.
          const value = (note as AllNotes)[fieldKey];

          return (
            <InfoCardField key={`${note.id}-${field.key}`} label={field.label} 
              value={value} fieldIndex={i} />
          );
        })}
        {renderCustomFields()}
      </>
    );
  };

  const renderEditNote = (): ReactElement => {
    switch (noteType) {
      case NoteType.AIRLINE:
        return (
          <EditAirlineNote airlineNote={noteCopy as AirlineNote} changeFunc={getChangeFunction} 
            headerChange={changeHeader}
            mutableCustom={mutableCustom} addCustomField={handleAddCustomField} onDelete={handleDeleteCustomField}
            handleCustomChange={getCustomChange}/>
        );
      case NoteType.PERSONAL:
        return (
          <EditPersonalNote personalNote={noteCopy as PersonalNote} changeFunc={getChangeFunction} 
            onDelete={handleDeleteCustomField}
            mutableCustom={mutableCustom} addCustomField={handleAddCustomField}
            handleCustomChange={getCustomChange}/>
        );
      case NoteType.LEADER: 
        return (
          <EditLeaderNote leader={noteCopy as Leader} changeFunc={getChangeFunction} onSetNewValue={handleSetNewValue}
            onDelete={handleDeleteCustomField} addError={handleAddError} removeError={handleRemoveError}
            mutableCustom={mutableCustom} addCustomField={handleAddCustomField} 
            handleCustomChange={getCustomChange}/>
        );
      case NoteType.HOTEL:
        return (
          <EditHotelNote hotelNote={noteCopy as HotelNote} changeFunc={getChangeFunction}
            onDelete={handleDeleteCustomField} headerChange={changeHeader}
            mutableCustom={mutableCustom} addCustomField={handleAddCustomField} 
            handleCustomChange={getCustomChange}/>
        );
      case NoteType.PROFILE:
        return (
          <EditProfileNote profileNote={noteCopy as ProfileNote} changeFunc={getChangeFunction}
            onDelete={handleDeleteCustomField}
            mutableCustom={mutableCustom} addCustomField={handleAddCustomField} 
            handleCustomChange={getCustomChange}/>
        );
      default:
        return <></>;
    }
  };

  const renderHeader = (): ReactElement => {
    switch (noteType) {
      case NoteType.AIRLINE:
        
        return (
          <>
            <AirlineCardHeader airline_data={(note as AirlineNote).airline_data} />
            <InfoCardControls collapsed={collapsed} toggleCollapsed={handleToggleCollapsed} 
              openDelete={currentLeader?.permissions.standard.basic.delete ? openDeleteAlert : undefined} 
              openEdit={currentLeader?.permissions.standard.airlines.edit ? handleEdit : undefined}/>
          </>
        );
      case NoteType.PERSONAL:
        return (
          <>
            <PersonalCardHeader personal_data={note as PersonalNote} />
            <InfoCardControls collapsed={collapsed} toggleCollapsed={handleToggleCollapsed} 
              openDelete={currentLeader?.permissions.standard.personal.delete ? openDeleteAlert : undefined} 
              openEdit={currentLeader?.permissions.standard.personal.edit ? handleEdit : undefined}/>
          </>
        );
      case NoteType.LEADER:
        return (
          <LeaderCardHeader openEdit={currentLeader?.permissions.standard.basic.edit ? handleEdit : undefined}/>
        );
      case NoteType.HOTEL:
        return (
          <>
            <HotelCardHeader hotel_data={(note as HotelNote).hotel_data} />
            <InfoCardControls collapsed={collapsed} toggleCollapsed={handleToggleCollapsed} 
              openDelete={currentLeader?.permissions.standard.hotels.delete ? openDeleteAlert : undefined} 
              openEdit={currentLeader?.permissions.standard.hotels.edit ? handleEdit : undefined}/>
          </>
        );
      case NoteType.PROFILE: {
        const profileNote = (note as ProfileNote);
        const notePermissions = currentLeader?.permissions.categories[profileNote.profile_category];
        return (
          <>
            <ProfileCardHeader profileNote={profileNote} />
            <InfoCardControls collapsed={collapsed} toggleCollapsed={handleToggleCollapsed} 
              openDelete={notePermissions?.delete ? openDeleteAlert : undefined} 
              openEdit={notePermissions?.edit ? handleEdit : undefined}/>
          </>
        );
      }
      default:
        return <></>;
    }
  };

  return (
    <>
      <CabModal
        title={addMode ? `Add ${title}` : `Edit ${title}`}
        open={addModal}
        onClose={handleCancel}
        closeIcon
        actionButtons={
          <>
            <CabButton onClick={handleCancel} buttonType='tertiary'>Cancel</CabButton>
            <CabButton onClick={handleSave} disabled={isSaving}>Save</CabButton>
          </>
        }
        sx={{ '& .MuiDialog-paper': { width: 575, maxHeight: '60%' } }}
      >
        {renderEditNote()}
      </CabModal>

      <WidgetContainer>
        <HeaderBox>
          {renderHeader()}
        </HeaderBox>
        {!collapsed && (
          <ContentBox> 
            {renderInfoCardFields()}
          </ContentBox>
        )}
      </WidgetContainer>

      <CabModal
        open={showErrorAlert}
        onClose={() => setShowErrorAlert(false)}
        title={'Error'}
        text={errorMessage}
        isAlert={true}
        noFullScreen={true}
        actionButtons={
          <CabButton onClick={() => setShowErrorAlert(false)}>
            OK
          </CabButton>
        }
      />
    </>
  );
}

export default InfoCardContainer;

const WidgetContainer = styled(Box, {label: "WidgetContainer"})({
  border: `1px solid ${colors.black200}`,
  backgroundColor: colors.white900,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  borderRadius: 6,
  padding: '16px 0px 16px 16px',
  width: '100%',
  height: '100%',
});

const HeaderBox = styled(Box, {label: "HeaderBox"})({
  display: 'flex',
  width: '100%',
  alignItems: 'center'
});

const ContentBox = styled(Box, {label: "ContentBox"})({
  overflow: 'auto',
  marginTop: 24,
  paddingRight: 8,
  overflowX: 'hidden',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    width: 8,
    height: 8,
    backgroundColor: colors.white900,
  },
  '::-webkit-scrollbar-thumb': {
    background: colors.black300,
    borderRadius: 4,
  }
});
