import { ReactElement } from 'react';
import { PersonalNote } from '../../../store';
import { NoteCustomFields, FixedNoteField, AddFieldButton } from '../EditNote/EditNote';
import { MutableCustom } from '../../../utils/inputUtils';
import { Box } from '@mui/material';

interface Props {
  personalNote: PersonalNote;
  changeFunc: (field: string, custom?: boolean, label?: boolean) => ((newValue: string) => void);
  handleCustomChange: (index: number, label?: boolean) => ((newValue: string) => void);
  mutableCustom: MutableCustom;
  addCustomField: () => void;
  onDelete: (index: number) => (() => void);
}

export const EditPersonalNote = 
({ personalNote, changeFunc, mutableCustom, addCustomField, onDelete, handleCustomChange}: Props): ReactElement => {

  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <FixedNoteField fieldLabelKey="name" placeholder="Enter a name" 
        value={personalNote.full_name} changeFunc={changeFunc('full_name')}
        disabled={personalNote.is_leader}/>

      <FixedNoteField fieldLabelKey="phone_number" placeholder="Enter a number" 
        value={personalNote.phone_number} changeFunc={changeFunc('phone_number')}/>

      <FixedNoteField fieldLabelKey="email" placeholder="Enter a email" 
        value={personalNote.email} changeFunc={changeFunc('email')}
        disabled={personalNote.is_leader}/>

      <FixedNoteField fieldLabelKey="relation" placeholder="e.g. Brother Daughter" 
        value={personalNote.relation} changeFunc={changeFunc('relation')}
        disabled={personalNote.is_leader}/>

      <FixedNoteField fieldLabelKey="known_traveler" placeholder="Enter a number" 
        value={personalNote.known_traveler} changeFunc={changeFunc('known_traveler')}/>

      <FixedNoteField fieldLabelKey="birthday" placeholder="Enter a date" 
        value={personalNote.birthday} changeFunc={changeFunc('birthday')}/>

      <FixedNoteField fieldLabelKey="health_insurance" placeholder="Enter insurance information"
        value={personalNote.health_insurance} changeFunc={changeFunc('health_insurance')}/>

      <FixedNoteField fieldLabelKey="address" placeholder="Enter an address" 
        value={personalNote.address} changeFunc={changeFunc('address')}/>

      <FixedNoteField fieldLabelKey="airline_seat_preference" placeholder="Enter a seat"
        value={personalNote.airline_seat_preference} changeFunc={changeFunc('airline_seat_preference')}/>

      <FixedNoteField fieldLabelKey="notes" placeholder="Enter a note" 
        value={personalNote.notes} changeFunc={changeFunc('notes')}/>
            
      <NoteCustomFields mutableCustom={mutableCustom} changeFunc={handleCustomChange} onDelete={onDelete} />

      <AddFieldButton onClick={addCustomField}/> 
    </Box>
  );
};

export default EditPersonalNote;