import React, {ReactElement} from 'react';
import { formatPhoneNumber } from '../../../utils/renderUtils';
import { PersonalNote } from '../../../store/personal/types';
import { Box, Typography } from '@mui/material';
import { CabIcon } from '@CabComponents/CabIcon';
import { IoPeople } from 'react-icons/io5';

interface Props {
  personal_data: PersonalNote;
}

export const PersonalCardHeader = ({ personal_data }: Props): ReactElement => {

  const formattedPhoneNumber = formatPhoneNumber(personal_data.phone_number);

  return (
    <Box display='flex' flexDirection='column' gap={1} overflow='hidden'>
      <Box marginTop={1} display='flex' alignItems='center'>
        <CabIcon Icon={IoPeople} sx={{height: '40px', width: '40px'}} />
        <Typography marginLeft={1} fontSize={22} fontWeight='bold'>{personal_data.full_name}</Typography>
      </Box>
      <Box display='flex' flexDirection='column' gap={.5}>
        <Box display='flex' alignItems='center' gap={1}>
          <Typography minWidth={75} fontWeight='bold'>Phone</Typography>
          <Typography onClick={(event) => event.stopPropagation()} noWrap textOverflow='ellipsis' 
            sx={{userSelect: 'text'}}>
            <a href={`tel:${formattedPhoneNumber}`}>
              {formattedPhoneNumber}
            </a>
          </Typography>
        </Box>
      
        <Box display='flex' alignItems='center' gap={1} width='100%'>
          <Typography minWidth={75} fontWeight='bold'>Email</Typography>
          <Typography onClick={(event) => event.stopPropagation()} noWrap textOverflow='ellipsis' 
            sx={{userSelect: 'text'}}>
            <a href={`mailto:${personal_data.email}`} target="_blank" rel="noopener noreferrer">
              {personal_data.email}
            </a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalCardHeader;