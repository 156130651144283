import {ReactElement} from 'react';
import { ProfileNote } from '../../../store';
import { Box, Typography } from '@mui/material';

interface Props {
  profileNote: ProfileNote;
}

export const ProfileCardHeader = ({ profileNote }: Props): ReactElement => {
  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <Box marginTop={1} display='flex' alignItems='center'>
        <Typography fontSize={22} fontWeight='bold'>{profileNote.title}</Typography>
      </Box>
      <Box display='flex' flexDirection='column' gap={.5}>
        <Box sx={{userSelect: 'text'}}>
          <i>{profileNote.subtitle}</i>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileCardHeader;