import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Leader, RootState } from '../../../store';
import { NoteCustomFields, FixedNoteField, AddFieldButton } from '../EditNote/EditNote';
import { MutableCustom } from '../../../utils/inputUtils';
import { ERROR_TYPE, NOTE_FIELD_LABELS } from '../../../constants';
import { selectCountries, selectIndustries } from '../../../store/staticData/selectors';
import { Box, FormControl, FormLabel } from '@mui/material';
import { CabAutocomplete } from '@CabComponents/CabAutocomplete';


interface ComponentProps {
  leader: Leader;
  changeFunc: (field: string, custom?: boolean, label?: boolean) => ((newValue: string) => void);
  mutableCustom: MutableCustom;
  handleCustomChange: (index: number, label?: boolean) => ((newValue: string) => void);
  addCustomField: () => void;
  onDelete: (index: number) => (() => void);
  addError: (fieldkey: keyof typeof NOTE_FIELD_LABELS, errorType: keyof typeof ERROR_TYPE) => void;
  removeError: (key: string, errorType: keyof typeof ERROR_TYPE) => void;
  onSetNewValue: (field: string, newValue: string) => void;
}

type Props = ComponentProps;
  
export const EditLeaderNote = ({
  leader, changeFunc, mutableCustom, addCustomField, onDelete, handleCustomChange, onSetNewValue
}: Props): ReactElement => {

  const countries = useSelector((state: RootState) => selectCountries(state.staticData));
  const industries = useSelector((state: RootState) => selectIndustries(state.staticData));
  const [country, setCountry] = useState(leader.country);
  const [industry, setIndustry] = useState(leader.industry);

  const countryOptions = countries.map(countryItr => ({
    value: countryItr.id,
    label: countryItr.name
  }));

  const industryOptions = industries.map(industryItr => ({
    value: industryItr.id,
    label: industryItr.display_name
  }));

  const setNewCountry = (newValue: string) => {
    setCountry(Number(newValue));
    onSetNewValue('country', newValue);
  };

  const setNewIndustry = (newValue: string) => {
    setIndustry(Number(newValue));
    onSetNewValue('industry', newValue);
  };
  
  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <Box display='flex' flexDirection='column' gap={1}>
        <FixedNoteField fieldLabelKey="postal_code" placeholder="Postal code" 
          value={leader.postal_code} changeFunc={changeFunc('postal_code')}/>
        <FormControl fullWidth>
          <FormLabel>Country</FormLabel>
          <CabAutocomplete<number, never>
            options={countryOptions}
            value={country}
            onChange={(e) => setNewCountry(String(e))}
            placeholder='Select Country'
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel>Industry</FormLabel>
          <CabAutocomplete<number, never>
            options={industryOptions}
            value={industry}
            onChange={(e) => setNewIndustry(String(e))}
            placeholder='Select Industry'
          />
        </FormControl>
      </Box>

      <NoteCustomFields mutableCustom={mutableCustom} changeFunc={handleCustomChange} onDelete={onDelete} />
        
      <AddFieldButton onClick={addCustomField}/>
    </Box>
  );
};
  
export default EditLeaderNote;